import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Button as MuiButton } from "@mui/material";
import { apiNodeService } from "../../../provider/apiNodeService";
import Checkbox from '@mui/material/Checkbox';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import countries from "../../../data/countries"
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import MuiBackdrop from "../../../components/Loading/MuiBackdrop";
import dayjs from 'dayjs';

const Signup_Build = () => {

    const navigate = useNavigate();

    const [email, setEmail] = React.useState('')
    const [nom, setNom] = React.useState('')
    const [prenom, setPrenom] = React.useState('')
    const [mobile, setMobile] = React.useState('')
    const [naissance, setNaissance] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [passwordVerif, setPasswordVerif] = React.useState('')
    const [validate, setValidate] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [isEmptyValue, setIsEmptyValue] = React.useState(false)
    const [isMatch, setIsMatch] = React.useState(false)
    const [inscritError, setInscritError] = React.useState(false)
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPassword1, setShowPassword1] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [step, setStep] = React.useState(1);
    const [adress, setAdress] = React.useState("");
    const [adressComplet, setAdressComplet] = React.useState("");
    const [codep, setCodep] = React.useState("");
    const [ville, setVille] = React.useState("");
    const [pays, setPays] = React.useState("");
    const [buildConnu, setBuildConnu] = React.useState("Sélectionner une valeur");
    const [besoin, setBesoin] = React.useState("");
    const [role, setRole] = React.useState("freelance");
    const [validPassword, setValidPassword] = React.useState(false);
    const [iban, setIban] = React.useState('')
    const [domaine, setDomaine] = React.useState('Transport et Logistique')
    const [vehiculeName, setVehiculeName] = React.useState('')
    const [immatricule, setImmatricule] = React.useState('')
    const [capaciteVehicule, setCapaciteVehicule] = React.useState(0)
    const [isElectrique, setIsElectrique] = React.useState(false)

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const email1 = searchParams.get('email');
        const prenom1 = searchParams.get('prenom');
        const nom1 = searchParams.get('nom');
        console.log(email1)
        console.log(prenom1)
        console.log(nom1)
        if (email1 && prenom1 && nom1) {
            setEmail(email1)
            setPrenom(prenom1)
            setNom(nom1)
            setNaissance(moment().format('YYYY-MM-DD'))
            setStep(2)
        }
    }, [])

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword1 = () => setShowPassword1((show) => !show);

    const handleMouseDownPassword1 = (event) => {
        event.preventDefault();
    };

    const getUserByEmail = async () => {
        return new Promise(resolve => {
            apiNodeService.filterTable({ table: "user_build", filter: { email: email }, db: "FREELANCE_MAD" }).then(userRes => {
                console.log(userRes)
                if (userRes && userRes !== "false" && Array.isArray(userRes.data)) {
                    console.log(userRes.data[0])
                    resolve(userRes.data[0])
                } else {
                    resolve("false")
                }
            }).catch(err => resolve("false"))
        })
    }

    const inscription = async () => {
        setLoading(true)
        setIsEmptyValue(false)
        setValidate(false)
        setIsMatch(false)
        setInscritError(false)
        let data = {
            first_name: prenom,
            last_name: nom,
            password: password === "" ? "Freelance2024!" : password,
            email: email,
            mobile: mobile,
            pays: pays,
            created_at: moment().format('YYYY-MM-DD'),
            ville: ville,
            adress: adress,
            codep: codep,
            besoin: besoin,
            connu: buildConnu,
            adress_complet: adressComplet,
            naissance: naissance,
            role: role,
            iban:iban,
            domaine:domaine,
            vehicule_name:vehiculeName,
            immatricule:immatricule,
            capacite_vehicule:capaciteVehicule,
            is_electrique:isElectrique
        }
        apiNodeService.registerFreelance(data).then(res => {
            console.log(res)
            if (res.status === 200) {
                localStorage.setItem("email", email)
                localStorage.setItem("first_name", prenom)
                localStorage.setItem("last_name", nom)
                localStorage.setItem("id", res.data.id)
                localStorage.setItem("role", role)
                navigate("/rh-employe?user=" + res.data.id)
                addOdooVehicule(res.data.id)
                addOdooEmploye()
                setLoading(false)
            } else {
                console.log("erreur")
                setValidate(true)
                setInscritError(true)
                setLoading(false)
            }
        })
    }

    const addOdooVehicule = (id) => {
        let data = {
            name: vehiculeName,
            electrique: isElectrique,
            matricul: immatricule,
            capacite: capaciteVehicule
        }
        apiNodeService.addVehiculeOdoo(data).then(res => {
            console.log(res)
            if (res.status === 200) {
                let data1 = {
                    vehicule_id:res.data
                }
                addOdooVendor(id,data1)
                // addOdooLivreur(id,data1)
            }
        })
    }

    const addOdooLivreur = (id,user_id) => {
        let data = {
            name: prenom + " " + nom,
            email: email,
            codep: codep,
            ville: ville,
            adress:adress,
            adress1:adressComplet,
            birthday:moment(naissance).format('YYYY-MM-DD'),
            poste:domaine,
            bank_id:id
        }
        apiNodeService.addLivreurOdoo(data).then(res => {
            console.log(res)
            if (res.status === 200) {
                let data1 = {employe_id : res.data}
                updateUser(data1,user_id)

                // let carriere = {
                //     name: prenom + " " + nom,
                //     freelance:res.data,
                //     vehicule:data1.vehicule_id
                // }
                // apiNodeService.addCarriereOdoo(carriere).then(res => {
                //     console.log(res)
                //     if (res.status === 200) {
                //        console.log(true)
                //     }
                // })
            }
        })
    }

    const addBankAccount = (partner_id,user_id) => {
        let data = {
            fname: prenom + " " +nom,
            partner_id: partner_id,
            iban: iban
        }
        apiNodeService.addBankAccountOdoo(data).then(res => {
            console.log(res)
            let data1 = {
                bank_id: res.data
            }
            updateUser(data1,user_id)
            addOdooLivreur(res.data,user_id)
        })
    }

    const updateUser = (data,id) => {
        apiNodeService.updateTableData({ db: "FREELANCE_MAD", table: "user_build", id: id[0], data: data }).then(resAdd => {
            if (resAdd && resAdd.status === 200) {
                console.log("true")
            } else {
                console.log("error")
            }
        })
    }

    const addOdooEmploye = () => {
        let data = {
            name: prenom + " " + nom,
            email: email,
            birthday: moment(naissance).format('YYYY-MM-DD'),
            mobile: mobile
        }
        apiNodeService.addEmployeOdoo(data).then(res => {
            console.log(res)
        })
    }

    const addOdooVendor = (id,data1) => {
        let data = {
            fname: prenom + " " + nom,
            email: email,
            city: ville,
            phone: mobile,
            street: adress,
            street2: adressComplet,
            zip: codep,
            company_type: "person",
            is_company: false
        }
        apiNodeService.addVendorOdoo(data).then(res => {
            console.log(res)
            if (res.status === 200) {
                data1.vendor_id = res.data
                updateUser(data1,id)
                apiNodeService.getBankData(iban).then(resAdd=>{
                    console.log(resAdd)
                    if (resAdd.valid === true) {
                        console.log('here i will implement the add account')
                        addBankAccount(res.data,id)
                    }
                })
            }
        })
    }

    const addOdooClient = () => {
        let data = {
            fname: prenom + " " + nom,
            email: email,
            city: ville,
            phone: mobile,
            street: adressComplet,
            zip: codep,
            company_type: "person",
            is_company: false
        }
        apiNodeService.addClientOdoo(data).then(res => {
            console.log(res)
        })
    }

    const validatePassword = (password) => {
        // Define regular expressions for each requirement
        const minLengthRegex = /.{8,}/;
        const uppercaseRegex = /[A-Z]/;
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
        const numberRegex = /[0-9]/;

        // Check if all requirements are met
        const isMinLengthValid = minLengthRegex.test(password);
        const isUppercaseValid = uppercaseRegex.test(password);
        const isSpecialCharValid = specialCharRegex.test(password);
        const isNumberValid = numberRegex.test(password);

        // Set valid state based on all requirements being met

        return isMinLengthValid &&
            isUppercaseValid &&
            isSpecialCharValid &&
            isNumberValid

    };


    return (

        <div style={{ overflowX: 'hidden' }}>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="row align-items-center">
                <div className="col-md-6">
                    <span className="title-build" style={{ fontSize: 36, color: '#000', fontWeight: 700 }}>Build.</span>
                    <span className="title-build1" style={{ fontSize: 36, color: '#214dcb', fontWeight: 700 }}>Mada</span>
                    <div className="d-flex flex-column section1-login mt-4">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="d-flex flex-column">
                                <span style={{ fontWeight: 700, marginRight: '7rem', fontSize: 18 }}>Renseignez vos informations</span>
                                <span style={{ fontSize: 16, fontWeight: 700 }} className="mb-2">*  = Champs obligatoires</span>
                            </div>
                        </div>
                        {step === 1 &&
                            <>
                                <div className="d-flex mt-3 align-items-center justify-content-center">
                                    <div className="d-flex" style={{ width: '22rem', gap: 8 }}>
                                        <div className="d-flex flex-column">
                                            <span className="span-title mb-1">Nom de famille*</span>
                                            <TextField size="small" id="outlined-basic" variant="outlined"
                                                value={nom}
                                                onChange={(e) => {
                                                    setNom(e.target.value)
                                                }} />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className="span-title mb-1">Prénom*</span>
                                            <TextField size="small" id="outlined-basic1" variant="outlined"
                                                value={prenom}
                                                onChange={(e) => {
                                                    setPrenom(e.target.value)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column date-section">
                                        <span className="span-title ">Date de naissance*</span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker format="DD/MM/YYYY" value={dayjs(moment(naissance))} style={{ width: '22rem' }} onChange={(e) => {
                                                    if (e) {
                                                        setNaissance(moment(e.$d).format('YYYY-MM-DD'))
                                                    }
                                                }} />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Email*</span>
                                        <TextField style={{ width: '22rem' }} size="small" id="outlined-basic2" variant="outlined"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Téléphone</span>
                                        <TextField style={{ width: '22rem' }} size="small" id="outlined-basic3" variant="outlined"
                                            value={mobile}
                                            onChange={(e) => {
                                                setMobile(e.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Mot de passe*</span>
                                        <FormControl style={{ width: '22rem' }} variant="outlined">
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                size="small"
                                                variant="filled"
                                                value={password}
                                                onChange={(e) => {
                                                    setPassword(e.target.value)
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-2">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Confirmer le mot de passe*</span>
                                        <FormControl style={{ width: '22rem' }} variant="outlined">
                                            <OutlinedInput
                                                id="outlined-adornment-password1"
                                                type={showPassword1 ? 'text' : 'password'}
                                                size="small"
                                                variant="filled"
                                                value={passwordVerif}
                                                onChange={(e) => {
                                                    setPasswordVerif(e.target.value)
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword1}
                                                            onMouseDown={handleMouseDownPassword1}
                                                            edge="end"
                                                        >
                                                            {showPassword1 ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="mt-2 d-flex align-items-center justify-content-center">
                                    <div style={{ width: '23rem' }} className="d-flex align-items-center">
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <span className="span-footer mb-1">J’accepte <b> les conditions de ventes</b> et <b> la politique de confidentialité.</b></span>
                                    </div>
                                </div>
                            </>
                        }
                        {step === 2 &&
                            <>
                                <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Adresse*</span>
                                        <TextField style={{ width: '22rem' }} size="small" id="outlined-basic8" variant="outlined"
                                            value={adress}
                                            onChange={(e) => {
                                                setAdress(e.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Complément d’adresse</span>
                                        <TextField style={{ width: '22rem' }} size="small" id="outlined-basic9" variant="outlined"
                                            value={adressComplet}
                                            onChange={(e) => {
                                                setAdressComplet(e.target.value)
                                            }} />
                                    </div>
                                </div>
                                <div className="d-flex mt-3 align-items-center justify-content-center">
                                    <div className="d-flex" style={{ width: '22rem', gap: 8 }}>
                                        <div className="d-flex flex-column">
                                            <span className="span-title mb-1">Code postal*</span>
                                            <TextField size="small" id="outlined-basic10" variant="outlined"
                                                value={codep}
                                                onChange={(e) => {
                                                    setCodep(e.target.value)
                                                }} />
                                        </div>
                                        <div className="d-flex flex-column">
                                            <span className="span-title mb-1">Ville*</span>
                                            <TextField size="small" id="outlined-basic11" variant="outlined"
                                                value={ville}
                                                onChange={(e) => {
                                                    setVille(e.target.value)
                                                }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex mt-3 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Pays*</span>
                                        <Autocomplete
                                            id="country-select-demo"
                                            sx={{ width: '22rem' }}
                                            options={countries.countries}
                                            className="countries-autocomplete"
                                            onChange={(e, selected) => {
                                                setPays(selected)
                                            }}
                                            autoHighlight
                                            getOptionLabel={(option) => option.label}
                                            renderOption={(props, option) => (
                                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                        alt=""
                                                    />
                                                    {option.label}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mt-3 align-items-center justify-content-center mb-2">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Comment avez vous connu(e) Build.Mada ?</span>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={buildConnu}
                                            size="small"
                                            onChange={(e) => { setBuildConnu(e.target.value) }}
                                            style={{ width: '22rem' }}
                                        >
                                            <MenuItem value={"Sélectionner une valeur"}>Sélectionner une valeur</MenuItem>
                                            <MenuItem value={"Youtube"}>Youtube</MenuItem>
                                            <MenuItem value={"Bouche à oreille"}>Bouche à oreille</MenuItem>
                                            <MenuItem value={"Google"}>Google</MenuItem>
                                            <MenuItem value={"Instagram"}>Instagram</MenuItem>
                                            <MenuItem value={"X/Twitter"}>X/Twitter</MenuItem>
                                            <MenuItem value={"Facebook"}>Facebook</MenuItem>
                                            <MenuItem value={"WhatsApp"}>WhatsApp</MenuItem>
                                            <MenuItem value={"Autre"}>Autre</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                {/* <div className="d-flex mt-3 mb-3 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Veuillez décrire votre besoin*</span>
                                        <TextField multiline
                                            rows={3} style={{ width: '22rem' }} id="outlined-basic13" variant="outlined"
                                            value={besoin}
                                            onChange={(e) => {
                                                setBesoin(e.target.value)
                                            }} />
                                    </div>
                                </div> */}
                            </>
                        }

                        {step === 3 &&
                            <>

                                <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-title mb-1">Je m’inscrit en tant que*</span>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select1"
                                            value={role}
                                            size="small"
                                            onChange={(e) => { setRole(e.target.value) }}
                                            style={{ width: '22rem' }}
                                        >
                                            <MenuItem value={"freelance"}>Freelance</MenuItem>
                                            <MenuItem value={"porteur"}>Porteur de projet</MenuItem>
                                        </Select>
                                    </div>
                                </div>
                                {role === "freelance" &&
                                    <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                            <span className="span-sign-data">IBAN*</span>
                                            <TextField style={{ width: '22rem' }} fullWidth size="small" onChange={(e) => { setIban(e.target.value) }} />
                                        </div>
                                    </div>
                                }
                                {role === "freelance" &&
                                    <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                        <span className="span-sign-data">Domaine d’activité*</span>
                                        <Select
                                            className='mt-1'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={domaine}
                                            style={{ width: '22rem',height: 40 }}
                                            size="small"
                                            onChange={(e) => {
                                                setDomaine(e.target.value)
                                            }}
                                            fullWidth
                                        >
                                            <MenuItem value={"Transport et Logistique"}>Transport et Logistique</MenuItem>
                                            <MenuItem value={"Technologie de l'information (IT)"}>Technologie de l'information (IT)</MenuItem>
                                            <MenuItem value={"Santé et Pharmacie"}>Santé et Pharmacie</MenuItem>
                                            <MenuItem value={"Finance et Assurance"}>Finance et Assurance</MenuItem>
                                            <MenuItem value={"Commerce et Distribution"}>Commerce et Distribution</MenuItem>
                                            <MenuItem value={"Énergie et Environnement"}>Énergie et Environnement</MenuItem>
                                            <MenuItem value={"Industrie et Manufacturing"}>Industrie et Manufacturing</MenuItem>
                                            <MenuItem value={"Agroalimentaire"}>Agroalimentaire</MenuItem>
                                            <MenuItem value={"Éducation et Formation"}>Éducation et Formation</MenuItem>
                                            <MenuItem value={"Tourisme et Hôtellerie"}>Tourisme et Hôtellerie</MenuItem>
                                            <MenuItem value={"Construction et Immobilier"}>Construction et Immobilier</MenuItem>
                                            <MenuItem value={"Médias et Divertissement"}>Médias et Divertissement</MenuItem>
                                            <MenuItem value={"Services Publics et Gouvernement"}>Services Publics et Gouvernement</MenuItem>
                                        </Select>
                                    </div>
                                    </div>
                                }
                                {(domaine === "Transport et Logistique" && role === "freelance") &&
                                    <>
                                        <div className="d-flex mt-2 align-items-center justify-content-center">
                                        <div className="d-flex flex-column">
                                                <span className="span-sign-data">Nom du véhicule utilisé pour le transport*</span>
                                                <TextField style={{ width: '22rem' }} fullWidth size="small" placeholder="Ex : Renault Zoé R110" onChange={(e) => { setVehiculeName(e.target.value) }} />
                                            </div>
                                        </div>

                                        <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex  align-items-center" style={{ width: '22rem',marginLeft:-14 }}>
                                            <Checkbox onChange={(e) => { setIsElectrique(e.target.checked) }} />
                                            <span style={{ color: "#293C89" }}>Est-il électrique ?</span>
                                        </div>
                                        </div>

                                        <div className="d-flex mt-2 align-items-center justify-content-center">
                                    <div className="d-flex flex-column">
                                                <span className="span-sign-data">Plaque d’immatriculation*</span>
                                                <TextField style={{ width: '22rem' }} fullWidth size="small" onChange={(e) => { setImmatricule(e.target.value) }} />
                                            </div>
                                        </div>

                                        <div className="d-flex mt-2 align-items-center justify-content-center mb-3">
                                    <div className="d-flex flex-column" style={{ marginLeft: -12 }}>
                                                <span className="span-sign-data">Capacité de chargement du véhicule (en m3)*</span>
                                                <TextField value={capaciteVehicule} style={{ width: 80 }} type="number" size="small" onChange={(e) => { setCapaciteVehicule(e.target.value) }} />
                                            </div>
                                        </div>
                                    </>
                                }

                            </>
                        }
                        <div style={{ gap: 5 }} className="mt-2 mb-2 d-flex flex-column align-items-center justify-content-center">
                            {step === 1 &&
                                <MuiButton variant="contained" color="primary" size="large"
                                    style={{ textTransform: "none", fontWeight: 600, height: 46, fontSize: "1.1rem", width: '22rem' }}
                                    disabled={nom === "" || prenom === "" || email === "" || naissance === "" || password === "" || passwordVerif === "" || checked === false ? true : false}
                                    onClick={async () => {
                                        const exist = await getUserByEmail()
                                        let isValidPassword = validatePassword(password)
                                        if (exist) {
                                            setValidate(true)
                                            setLoading(false)
                                        } else {
                                            if (isValidPassword === false) {
                                                setValidPassword(true)
                                            } else if (password !== passwordVerif) {
                                                setIsMatch(true)
                                                setValidPassword(false)
                                                setLoading(false)
                                            } else {
                                                setIsMatch(false)
                                                setValidPassword(false)
                                                // setStep(2)
                                                addOdooEmploye()
                                            }
                                        }
                                    }}
                                    fullWidth={true}
                                >
                                    S’inscrire
                                </MuiButton>
                            }
                            {step === 2 &&
                                <MuiButton variant="contained" color="primary" size="large"
                                    style={{ textTransform: "none", fontWeight: 600, height: 46, fontSize: "1.1rem", width: '22rem' }}
                                    disabled={adress === "" || ville === "" || codep === "" || pays === "" || role === "" ? true : false}
                                    onClick={() => {
                                        setStep(3)
                                    }}
                                    fullWidth={true}
                                >
                                    Suivant
                                </MuiButton>
                            }
                            {step === 3 &&
                                <MuiButton variant="contained" color="primary" size="large"
                                    style={{ textTransform: "none", fontWeight: 600, height: 46, fontSize: "1.1rem", width: '22rem' }}
                                    disabled={((role === "freelance" && iban === "") || (domaine === "Transport et Logistique" && ( immatricule === "" || parseInt(capaciteVehicule) < 1 || capaciteVehicule === "" || vehiculeName === ""))) ? true : false}
                                    onClick={() => {
                                        inscription()
                                    }}
                                    fullWidth={true}
                                >
                                    Valider
                                </MuiButton>
                            }
                            <button
                                style={{ textTransform: "none", fontWeight: 600, height: 46, fontSize: "1.1rem", width: '8rem', border: 'none', color: "#616161", borderBottom: "2px solid #616161" }}
                                onClick={() => {
                                    console.log(naissance)
                                    if (step === 1) {
                                        navigate('/build-mada')
                                    } else if (step === 2) {
                                        setStep(1)
                                    } else if (step === 3) {
                                        setStep(2)
                                    }
                                }}
                                fullWidth={true}
                            >
                                Retour
                            </button>
                        </div>

                        <div className="mb-2 d-flex align-items-center justify-content-center">
                            <div style={{ width: '22rem' }}>
                                {validate === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}> Email existe !</span>}
                                {validPassword === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>Mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 chiffre et 1 caractère spécial</span>}
                                {isEmptyValue === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}> Veuillez remplir les champs *</span>}
                                {isMatch === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>Veuillez vérifier le mot de passe !</span>}
                                {inscritError === true && <span className="mt-1" style={{ color: "red", marginLeft: 2 }}>Erreur survenue !</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 signup-section2 p-0">
                </div>
            </div>
        </div>
    )

}
export default Signup_Build;