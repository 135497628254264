import FileUpload from "../components/Documents/FileUpload";
import { workspaces } from "../data/workspaces";
import { apiNodeService } from "../provider/apiNodeService";
import { Card, CardContent, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { folderTagsOdoo } from "../data/folderTagsOdoo";
import Chip from '@mui/material/Chip';
import MuiBackdrop from "../components/Loading/MuiBackdrop";
import { useToast } from "@chakra-ui/react";

const UploadDocToOdoo = () => {

    const [selectedCard, setSelectedCard] = useState(1);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const toast = useToast();

    useEffect(() => {
        getTagsOdooDoc(1)
    }, []);

    const getTagsOdooDoc = (id) => {
        setLoading(true)
        apiNodeService.getAllTagsOdoo(id).then(res => {
            console.log(res)
            if (res && res.status === 200) {
                setTags(res.data)
                setLoading(false)
            }
        })
    }

    const handleFileUpload = async (file) => {
        setLoadingUpload(true)
        const formData = new FormData();
        formData.append('file', file);
        formData.append('folder_id', selectedCard);
        selectedTags.forEach((tagId, index) => {
            formData.append(`tag_ids[${index}]`, tagId);
        });
        apiNodeService.uploadDocOdoo(formData).then(res => {
            console.log(res)
            if (res && res.status === 200) {
                setLoadingUpload(false)
                setSelectedTags([])
                toast({
                    title: 'Succès !',
                    description: "Document a été ajouter dans odoo documents avec succès",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                setLoadingUpload(false)
                toast({
                    title: 'Erreur !',
                    description: "Erreur survenu, réessayer ultérieurement",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        })
    };

    const handleCardChange = (event) => {
        setSelectedCard(event.target.value);
        getTagsOdooDoc(event.target.value)
    };

    const getSelectedCard = () => {
        return `Espace sélectionnée : ${selectedCard}`;
    };

    const handleTagChange = (event, newValue) => {
        console.log(newValue)
        const newSelectedIds = newValue.map(tag => tag.id.toString());
        setSelectedTags(newSelectedIds);
        console.log(newSelectedIds)

    };

    return (
        <>
            <MuiBackdrop open={loading} text={"Chargement..."} />
            <div className="p-3">
                <div className="container mt-4 mb-4">
                    <Typography variant="h4" className="mb-4">Sélectionnez un espace de travail</Typography>
                    <RadioGroup
                        aria-label="card-selection"
                        name="card-selection"
                        value={selectedCard}
                        onChange={handleCardChange}
                    >
                        <div className="row">
                            {workspaces.map((card) => (
                                <div key={card.id} className="col-md-4 mb-3">
                                    <Card className="h-100">
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {card.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Espace de travail {card.name}
                                            </Typography>
                                            <FormControlLabel
                                                value={card.id}
                                                control={<Radio />}
                                                label={`Sélectionner l'espace ${card.id}`}
                                                className="mt-2"
                                            />
                                        </CardContent>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </RadioGroup>
                    <Typography variant="h6" className="mt-3 mb-2">
                        {/* {getSelectedCard()} */}
                        Choisir tags
                    </Typography>

                    <Autocomplete
                        multiple
                        id="tags-standard"
                        className="mb-4"
                        options={tags}
                        sx={{maxWidth:300}}
                        getOptionLabel={(option) => option.name}
                        value={tags.filter(tag => selectedTags.includes(tag.id.toString()))}
                        onChange={handleTagChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Sélectionnez les tags"
                                placeholder="Tags"
                            />
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    label={option.name}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />

                    <FileUpload lang={'fr'} isLoading={loadingUpload} handleFileUpload={handleFileUpload} />
                </div>
            </div>
        </>
    );
}

export default UploadDocToOdoo;