export const workspaces = [
    {
        "id":1,
        "name":"Intern"
    },
    {
        "id":2,
        "name":"Finance"
    },
    {
        "id":3,
        "name":"Marketing"
    },
    {
        "id":12,
        "name":"Contrats et accords"
    },
    {
        "id":11,
        "name":"Investissements et actifs"
    },
    {
        "id":10,
        "name":"Fiscalité"
    },
    {
        "id":8,
        "name":"Gestion"
    },

]