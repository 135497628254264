export const folderTagsOdoo = [
  {
    "id_folder": 1,
    "tags": [
      {
        "id": 1,
        "name": "Boaty Fidirana"
      },
      {
        "id": 2,
        "name": "Ho Hamafisina"
      },
      {
        "id": 3,
        "name": "Voamarina"
      },
      {
        "id": 4,
        "name": "Tsy Ampiasaina"
      },
      {
        "id": 5,
        "name": "Fitantanana Olona"
      },
      {
        "id": 7,
        "name": "Ara-dalàna"
      },
      {
        "id": 6,
        "name": "Fivarotana"
      },
      {
        "id": 10,
        "name": "Fifanarahana"
      },
      {
        "id": 8,
        "name": "Hafa"
      },
      {
        "id": 9,
        "name": "Fampisehoana"
      },
      {
        "id": 11,
        "name": "Tetikasa"
      },
      {
        "id": 12,
        "name": "Lahatsoratra"
      }
    ]
  },
  {
    "id_folder": 2,
    "tags": [
      {
        "id": 13,
        "name": "Boaty Fidirana"
    },
    {
        "id": 14,
        "name": "Ho Hamafisina"
    },
    {
        "id": 15,
        "name": "Voamarina"
    },
    {
        "id": 16,
        "name": "Faktiora"
    },
    {
        "id": 52,
        "name": "Faktioran'ny mpamatsy"
    },
    {
        "id": 17,
        "name": "Fandaniana"
    },
    {
        "id": 53,
        "name": "Faktioran'ny mpanjifa"
    },
    {
        "id": 54,
        "name": "Tatitra momba ny banky"
    },
    {
        "id": 18,
        "name": "Taham-panjakana (VAT)"
    },
    {
        "id": 55,
        "name": "Fanambarana VAT"
    },
    {
        "id": 19,
        "name": "Ara-ketra"
    },
    {
        "id": 56,
        "name": "Tatitra ara-bola"
    },
    {
        "id": 20,
        "name": "Ara-bola"
    },
    {
        "id": 57,
        "name": "Tatitra momba ny vokatra"
    },
    {
        "id": 21,
        "name": "Fifanarahana"
    },
    {
        "id": 22,
        "name": "2024"
    },
    {
        "id": 58,
        "name": "Fanamarihana ny fandaniana"
    },
    {
        "id": 23,
        "name": "2023"
    },
    {
        "id": 59,
        "name": "Fandoavana"
    },
    {
        "id": 60,
        "name": "Fampisehoana tolotra"
    },
    {
        "id": 61,
        "name": "Vola aloany mialoha"
    },
    {
        "id": 62,
        "name": "Famatsiana"
    },
    {
        "id": 63,
        "name": "Fanoratana an-tsoratra ara-bola"
    },
    {
        "id": 64,
        "name": "Fanambarana hetra"
    },
    {
        "id": 65,
        "name": "Tatitra momba ny vola mivezivezy"
    },
    {
        "id": 66,
        "name": "Fandraisana vola"
    },
    {
        "id": 67,
        "name": "Famoahana vola"
    },
    {
        "id": 68,
        "name": "Famerenana vola"
    },
    {
        "id": 69,
        "name": "Fanaovana amortization"
    },
    {
        "id": 70,
        "name": "Fanomezana fanampiana"
    },
    {
        "id": 71,
        "name": "Faktiora miverimberina"
    },
    {
        "id": 72,
        "name": "Fanambarana hetra mialoha (DIA)"
    }
    ]
  },
  {
    "id_folder": 3,
    "tags": [
      {
        "id": 24,
        "name": "Doka"
    },
    {
        "id": 25,
        "name": "Taratasy fampahafantarana"
    },
    {
        "id": 26,
        "name": "Sary"
    },
    {
        "id": 27,
        "name": "Lahatsary"
    }
    ]
  },
  {
    "id_folder": 8,
    "tags": [
      {
        "id": 41,
        "name": "Fifanarahana mpamatsy"
    },
    {
        "id": 42,
        "name": "Fifanarahana mpanjifa"
    },
    {
        "id": 43,
        "name": "Taratasim-panafatra"
    },
    {
        "id": 44,
        "name": "Taratasim-panatobiana"
    },
    {
        "id": 45,
        "name": "Tolotra ara-barotra"
    },
    {
        "id": 46,
        "name": "Preventy"
    },
    {
        "id": 47,
        "name": "Fandraisana trosa"
    },
    {
        "id": 48,
        "name": "Fitsipika anatiny"
    },
    {
        "id": 49,
        "name": "Fanendrena"
    },
    {
        "id": 50,
        "name": "Antontan-taratasy ara-dalàna"
    },
    {
        "id": 51,
        "name": "Tatitra famotopotorana"
    }
    ]
  },
  {
    "id_folder": 10,
    "tags": [
      {
        "id": 84,
        "name": "Hevitra sarany (TVA)"
    },
    {
        "id": 85,
        "name": "Fanambarana hetra faritany"
    },
    {
        "id": 86,
        "name": "Hevitra faritany"
    },
    {
        "id": 87,
        "name": "Hevitra kaominina"
    },
    {
        "id": 88,
        "name": "Hetra aloha mialoha"
    },
    {
        "id": 89,
        "name": "Hetra amin'ny tombom-barotra"
    },
    {
        "id": 90,
        "name": "Hetra miharo amin'ny loharanom-bola"
    },
    {
        "id": 91,
        "name": "Fandraisana anjara AVS"
    },
    {
        "id": 92,
        "name": "Fanambarana hetra amin'ny karama"
    },
    {
        "id": 93,
        "name": "Zo amin'ny timbra federaly"
    }
    ]
  },
  {
    "id_folder": 11,
    "tags": [
      {
        "id": 94,
        "name": "Fananan-tsoa"
    },
    {
        "id": 95,
        "name": "Fivarotana fananana"
    },
    {
        "id": 96,
        "name": "Fividianana fananana"
    },
    {
        "id": 97,
        "name": "Fitahirizam-bokatra"
    },
    {
        "id": 98,
        "name": "Sisa amin'ny sanda"
    },
    {
        "id": 99,
        "name": "Fihenan'ny sanda"
    }
    ]
  },
  {
    "id_folder": 12,
    "tags": [
      {
        "id": 100,
        "name": "Contrat de bail"
    },
    {
        "id": 101,
        "name": "Accord de partenariat"
    },
    {
        "id": 102,
        "name": "Accord de confidentialité"
    },
    {
        "id": 103,
        "name": "Contrat d'assurance"
    },
    {
        "id": 104,
        "name": "Contrat de prestation"
    }
    ]
  }

]