import React, { useState } from 'react';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CircularProgress from '@mui/material/CircularProgress';

function FileUpload({ handleFileUpload, isLoading, lang }) {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <div className='d-flex flex-column gap-3'>
      <input type="file" onChange={handleFileChange} />
      <div className='d-flex gap-3'>
      <Button style={{maxWidth:300}} onClick={handleUpload} variant="contained" endIcon={<FileUploadIcon />}>
        {lang === 'fr' ? "Upload" : "Ampidiro"}
      </Button>
      {isLoading === true && <CircularProgress size="30px" />}
      </div>
    </div>
  );
}

export default FileUpload;
