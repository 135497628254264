export const workspacesmg = [
    {
        "id":1,
        "name":"Mpianatra"
    },
    {
        "id":2,
        "name":"Ara-bola"
    },
    {
        "id":3,
        "name":"Fampiroboroboana"
    },
    {
        "id":12,
        "name":"Fifanarahana sy fifanekena"
    },
    {
        "id":11,
        "name":"Fampiasam-bola sy fananana"
    },
    {
        "id":10,
        "name":"Fisoratana anarana"
    },
    {
        "id":8,
        "name":"Fitantanana"
    },

]