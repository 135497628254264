const endpoint = process.env.REACT_APP_API
var myHeaders = new Headers();
myHeaders.append("apikey", "ARPUBxUqyMsIfxc6s47iFML4HK8GayVH");

var requestOptions = {
  method: 'GET',
  redirect: 'follow',
  headers: myHeaders
};

export const apiNodeService = {

    login(data){
        return fetch(endpoint+'/api/bankapp/auth/login', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    loginFreelance(data){
        return fetch(endpoint+'/api/freelance/auth/login', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    contact(data){
        return fetch(endpoint+'/api/contact', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    coursRegister(data){
        return fetch(endpoint+'/api/course/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    register(data){
        return fetch(endpoint+'/api/auth/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    registerBank(data){
        return fetch(endpoint+'/api/bankapp/auth/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    registerFreelance(data){
        return fetch(endpoint+'/api/freelance/auth/register', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    verifEmailBank(data){
        return fetch(endpoint+'/api/bankapp/auth/verifEmail', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    achatUnit(data){
        return fetch(endpoint+'/api/bankapp/creditAccount', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    transferUnit(data){
        return fetch(endpoint+'/api/bankapp/transfer', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getAllUsers(){
        return fetch(endpoint+'/api/bankapp/allUsers', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getAllProjects(){
        return fetch(endpoint+'/api/bankapp/invest/projects', {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getProjectById(id){
        return fetch(endpoint+'/api/bankapp/invest/project/'+id, {
            method: 'GET',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    investSolar(id,data){
        return fetch(endpoint+'/api/bankapp/invest/project/'+id, {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getHistory(data){
        return fetch(endpoint+'/api/bankapp/userHistory', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    emailProcess(data){
        return fetch(endpoint+'/api/bankapp/invest/beginMailProcess', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    resetPassword(data){
        return fetch(endpoint+'/api/bankapp/auth/resetPassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    updatePassword(data){
        return fetch(endpoint+'/api/bankapp/auth/updatePassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    resetPasswordFreelance(data){
        return fetch(endpoint+'/api/freelance/auth/resetPassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    updatePasswordFreelance(data){
        return fetch(endpoint+'/api/freelance/auth/updatePassword', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    filterTable(data){
        return fetch(endpoint+'/api/rethink/filterTableData', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getTableData(data){
        return fetch(endpoint+'/api/rethink/getTableData', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    insertTableData(data){
        return fetch(endpoint+'/api/rethink/insertTableRow', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    updateTableData(data){
        return fetch(endpoint+'/api/rethink/updateTableRow', {
            method: 'POST',
            headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getEmployeeOdoo(){
        return fetch(endpoint+'/api/odoo/get_employee', {
            method: 'GET',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    addEmployeOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/add_employe', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    addVendorOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/add_vendor_solar', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    addClientOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/add_client', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    generateDoc(data){
        console.log(data)
        return fetch(endpoint+'/api/generate/doc', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    signDoc(data){
        console.log(data)
        return fetch(endpoint+'/api/sign/doc', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    signDevis(data){
        console.log(data)
        return fetch(endpoint+'/api/sign/devis', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    signNda(data){
        console.log(data)
        return fetch(endpoint+'/api/sign/allpdf', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    addVehiculeOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/add_vehicule', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    addLivreurOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/add_livreur', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    addCarriereOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/add_carriere', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    addBankAccountOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/add_account_bank_solar', {
            method: 'POST',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getBankData(iban){
        return fetch("https://api.apilayer.com/bank_data/iban_validate?iban_number="+iban, requestOptions)
          .then(response => response.json())
          .catch(error => console.log('error', error));
    },
    uploadDocOdoo(data){
        console.log(data)
        return fetch(endpoint+'/api/odoo/upload_doc', {
            method: 'POST',
            body: data
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
    getAllTagsOdoo(id){
        return fetch(endpoint+'/api/odoo/document-tags?folderId='+id, {
            method: 'GET',
            headers:{'Accept': 'application/json', 'Content-Type': 'application/json'},
        }).then(response => response.json()).catch(error => {
            console.log(error);
        });
    },
};
